import React, { useCallback } from 'react';

import Feature from '../components/Feature';
import Card from '../components/Card';
import InfoCarousel from '../components/InfoCarousel';
import BannerFeature from '../components/BannerFeature';
import Section from '../components/Section';

export default function usePageBody() {
	const renderPageBody = useCallback( component => {
		switch ( component.type ) {
			case 'feature':
				return component.features.map( ( feat, i ) => (
					<Feature
						key={ i }
						moduleId={ feat.moduleId }
						heading={ feat.heading }
						copy={ feat.copy }
						variant={ feat.variant }
						img={ feat.img }
						cropped={ feat.croppedImg }
						imgBorder={ feat.imgBorder }
						backgroundColor={ feat.backgroundColor }
						backgroundImage={ feat.backgroundImg }
						headerColor={ feat.headerColor }
						textColor={ feat.textColor }
						border={ feat.border }
					/>
				) );

			case 'cards':
				return component.cardGroup.map( ( cards, i ) => (
				<Section key={ i } 	id={cards.moduleId || ''} class="pt-100 features-section division">
				<div className="container">
					<div className="row justify-content-center">	
						<div className="col-md-10 col-lg-9">
							<div className="section-title mb-70">	
								<h2 className="s-52 w-700">{ cards.heading }</h2>		
							</div>	
						</div>
					</div>
					<div className="fbox-wrapper">
						<div className="row row-cols-1 row-cols-md-2 rows-3">
							{ cards.card.map( ( ca, i ) => (
								<Card
									key={ i }
									moduleId={ ca.moduleId }
									variant={ ca.type }
									heading={ ca.heading }
									subHeading={ ca.subHeading }
									copy={ ca.type === 'navCard' ? ca.navCopy : ca.featureCopy }
									icon={ ca.icon }
									img={ ca.img }
									nav={ ca.nav }
									grid={ cards.cardsLayout }
								/>
							) ) }
						</div>
					</div>
				</div>
				</Section>
				) );

			case 'featureCarousel':
				return (
					<InfoCarousel key={ component.i } data={ component.features } />
				);

			case 'banners':
				return (
					<BannerFeature key={ component.i } banner={ component.banner } />
				);

			case 'html':
				return <div key={component.i} dangerouslySetInnerHTML={{ __html: component.htmlInput }}/>;

			default:
				return null;
		}
	}, [] );

	const renderPageBodyPreview = useCallback( ( component, getAsset ) => {
		switch ( component.type ) {
			case 'feature':
				if ( component.features ) {
					return component.features.map( ( feat, i ) => (
						<Feature
							key={ i }
							heading={ feat.heading }
							copy={ feat.copy }
							variant={ feat.variant }
							img={ feat.img ? getAsset( feat.img ).url : '' }
							cropped={ feat.croppedImg }
							imgBorder={ feat.imgBorder }
							backgroundColor={ feat.backgroundColor }
							backgroundImage={ feat.backgroundImg }
							headerColor={ feat.headerColor }
							textColor={ feat.textColor }
							border={ feat.border }
						/>
					) );
				}

				return null;

			case 'cards':
				return component.cardGroup.map( ( cards, i ) => (
					<Section id={ cards.moduleId } key={ i }>
						{ cards.heading
							&& <h2 className={`text-${ cards.headerColor }`}>{ cards.heading }</h2>
						}
						<div className="grid">
							{ cards.card && cards.card.length > 0 && cards.card.map( ( ca, i ) => {
								return (
									<Card
										key={ i }
										variaint={ ca.type }
										heading={ ca.heading }
										subHeading={ ca.subHeading }
										copy={ ca.type === 'navCard' ? ca.navCopy : ca.featureCopy }
										icon={ !getAsset( ca.icon ).fileObj && getAsset( ca.icon ).url }
										img={ !getAsset( ca.img ).fileObj && getAsset( ca.img ).url }
										nav={ ca.nav }
										grid={ cards.cardsLayout } />
								);
							} )}
						</div>
					</Section>
				) );

			case 'featureCarousel':
				return (
					<>
						{ Boolean( component.features )
						&& <InfoCarousel key={ component.i } data={ component.features.map( feat => ( { ...feat, img: getAsset( feat.img ).url || null } ) ) } />
						}
					</>
				);

			case 'banners':
				return (
					<>
						{ Boolean( component.banner ) && <BannerFeature key={ component.i } banner={ component.banner } />}
					</>
				);

			case 'html':
				return <div key={component.i} dangerouslySetInnerHTML={{ __html: component.htmlInput }}/>;

			default:
				return null;
		}
	}, [] );

	return { renderPageBody, renderPageBodyPreview };
}
