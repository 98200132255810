import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { AnimatePresence, motion } from 'framer-motion';

import Feature from '../Feature';
// import * as styles from './infoCarousel.module.css';
import Section from '../Section';

const InfoCarousel = ( { data } ) => {
	const [ activeIndex, setActiveIndex ] = useState( 0 );

	const clickHandler = e => {
		const dataIndex = data.indexOf( data.find( item => item.heading === e.currentTarget.textContent ) );

		setActiveIndex( dataIndex );
	};

	return (
		<Section>
			<div className="carouselTabs">
				{data.map( ( item, i ) => (
					<React.Fragment key={ i }>
						<div
							className={ `tab ${ activeIndex === i ? 'infoCarouselSelected' : '' }`}
							onClick={ clickHandler }
						>
							<p className="overline">{ item.heading }</p>
						</div>
						{ i !== data.length - 1 && <div className="divider" /> }
					</React.Fragment>
				) )}
			</div>
			<AnimatePresence exitBeforeEnter>
				<motion.div
					key={ activeIndex }
					initial={{ opacity: 0 }}
					animate={{ opacity: 1 }}
					exit={{ opacity: 0 }}
					transition={{ duration: 0.6, ease: 'easeInOut' }}
				>
					<Feature
						carousel
						moduleId={ data[ activeIndex ].moduleId || ''}
						heading={ data[ activeIndex ].heading || 'FeatureContent' }
						copy={
							data[ activeIndex ].copy || [
								{
									type: 'text',
									text: 'Lorem, ipsum dolor sit amet consectetur adipisicing elit. Illum accusantium, labore eos deleniti corporis rem libero sed at atque saepe! Voluptatibus minus ducimus maxime rerum deleniti ipsa dolor dignissimos nemo.'
								}
							]
						}
						variant={ data[ activeIndex ].variant || 'left' }
						img={ data[ activeIndex ].img || 'https://images.unsplash.com/photo-1586797290707-aa6d01d0dd77?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=987&q=80' }
						cropped={ data[ activeIndex ].croppedImg || true }
						backgroundColor={ data[ activeIndex ].backgroundColor || 'primary-white' }
						backgroundImage={ data[ activeIndex ].backgroundImg || false }
						headerColor={ data[ activeIndex ].headerColor || 'primary-100' }
						textColor={ data[ activeIndex ].textColor || 'primary-200' }
						border={ data[ activeIndex ].border || false }
					/>
				</motion.div>
			</AnimatePresence>
		</Section>
	);
};

InfoCarousel.propTypes = {	data: PropTypes.array };

export default InfoCarousel;
