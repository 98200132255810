import React from 'react';
import PropTypes from 'prop-types';

const Arrow = () => {
	return (
		<svg width="100%" viewBox="0 0 36 15" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path
				d="M18 15L0.679488 0.000190183L35.3205 0.000187154L18 15Z" fill="white"/>
		</svg>
	);
};

Arrow.propTypes = { active: PropTypes.bool };

export default Arrow;
