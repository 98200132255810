import React from 'react';
import PropTypes from 'prop-types';
import { useInView } from 'react-intersection-observer';
import { motion } from 'framer-motion';

import { float } from '../../utils/framerVariants';

const Button = ( { buttonText, primary, secondary, outline, nav, ...props } ) => {
	const [ ref, isVisible ] = useInView( { threshold: 0.2 } );

	return (
		<motion.button
			ref={ ref }
			variants={ float }
			animate={ isVisible ? 'visible' : 'hidden' }
			transition={{ duration: 0.3, ease: 'easeInOut', delayChildren: 0.1, scatterChildren: 0.2 }}
			className={ `${ 'button' } ${ primary && 'primaryButton' } ${ secondary && 'secondaryButton' } ${ outline && 'outlineButton' } ${ nav && 'navButton' }` }
			type="button"
			{ ...props }
		>
			<p className="overline">{ buttonText }</p>
		</motion.button>
	);
};

Button.defaultProps = { primary: true, secondary: false, outline: false };

Button.propTypes = {
	buttonText: PropTypes.string.isRequired,
	primary: PropTypes.bool,
	secondary: PropTypes.bool,
	outline: PropTypes.bool,
	nav: PropTypes.bool
};

export default Button;
