import React from 'react';
import PropTypes from 'prop-types';
import MarkdownRenderer from '../../components/MarkdownRenderer';
import { motion } from 'framer-motion';

import ButtonGroup from '../Button/ButtonGroup';
import Section from '../Section';

import { fade, featureBannerVar } from '../../utils/framerVariants';

const BannerFeature = ( { banner } ) => {
	return (
		<Section
			id={ banner.moduleId || '' }
			className={`bg-${ banner.backgroundColor || 'primary-300' } ${ banner.border ? 'border-primary-black' : '' } text-${ banner.textColor || 'primary-black' }`}
			style={{
				backgroundImg:
          banner.backgroundImg ? `url(
          ${ banner.backgroundImg.childImageSharp
			? banner.backgroundImg.childImageSharp.fluid.src
			: banner.backgroundImg.extension === 'svg'
				? banner.backgroundImg.publicURL : banner.backgroundImg })` : '',
				backgroundRepeat: 'no-repeat',
				backgroundSize: 'cover',
				backgroundAttachment: 'fixed',
				backgroundPosition: 'center',
				textAlign: 'center'
			}}
		>
			<motion.div
				variants={ featureBannerVar }
				initial="hidden"
				animate="visible"
				style={{ marginBottom: '1rem' }}
			>
				<motion.h2 variants={ fade } className={`text-${ banner.headerColor || 'darkened-100' }`} style={{ margin: '0 auto 0.5rem' }}>{ banner.heading }</motion.h2>
				<motion.div variants={ fade }>
					<MarkdownRenderer copy={ banner.copy || 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Natus, quod!' } />
				</motion.div>
			</motion.div>
			<ButtonGroup buttons={ banner.button || [] } center />
		</Section>
	);
};

BannerFeature.defaultProps = {
	banner: {
		backgroundColor: '--primary-300',
		headerColor: '--primary-100',
		textColor: '--primary-black',
		heading: 'Default Banner',
		copy: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Veniam, harum.'
	}
};
BannerFeature.propTypes = { banner: PropTypes.object };

export default BannerFeature;
