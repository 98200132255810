import React from 'react';
import { useInView } from 'react-intersection-observer';
import PropTypes from 'prop-types';
import { motion } from 'framer-motion';

import { scrollVar } from '../../utils/framerVariants';

const Section = props => {
	const [ ref, isVisible ] = useInView( { threshold: 0.2 } );

	return (
		<motion.section
			ref={ ref }
			variants={ scrollVar }
			animate={ isVisible ? 'visible' : 'hidden' }
			transition={{ duration: 0.9, ease: 'easeInOut', delayChildren: 0.1, scatterChildren: 0.2 }}
			{ ...props }
		>
			{ props.children }
		</motion.section>
	);
};

Section.propTypes = { children: PropTypes.node };

export default Section;
