import React from 'react';
import PropTypes from 'prop-types';
import { AnimatePresence, motion, useAnimation } from 'framer-motion';
import { dropDownSectionVar } from '../../utils/framerVariants';

import Arrow from './Arrow';

const Base = ( { children } ) => {
	return (
		<div className="dropDownBase">{children}</div>
	);
};

Base.propTypes = { children: PropTypes.node };

const Section = ( { active, setActive, title, children } ) => {
	const controls = useAnimation();

	const animateIn = () => controls.start( { opacity: 0 } );
	const animateOut = () => controls.stop();

	return (
		<>
			<motion.button
				className="dropDownButton"
				animate={ controls }
				onTapStart={ animateOut }
				onTapCancel={ animateIn }
				active={ active }
				onClick={ () => setActive( !active ) }
			>
				<h5 style={{ margin: 0 }}>{ title }</h5>
				<div className="arrow transition" style={{ transform: !active ? '' : 'rotate(180deg)' }}>
					<Arrow />
				</div>
			</motion.button>
			<AnimatePresence>
				{ active && (
					<motion.div
						className="dropDownSection"
						variants={dropDownSectionVar}
						initial="hidden"
						animate="visible"
						exit="hidden"
						transition={{ duration: 0.3, type: 'just', ease: 'easeInOut' }}
					>
						{ children }
					</motion.div>
				) }
			</AnimatePresence>
		</>
	);
};

Section.propTypes = {
	active: PropTypes.bool,
	setActive: PropTypes.func,
	title: PropTypes.string,
	children: PropTypes.node
};

export const activeSetter = ( { active, setActive }, eventKey ) => {
	let list = [ ...active ];
	let isActive = list.find( key => key === eventKey );

	isActive ? list.splice( list.indexOf( isActive ), 1 ) : list.push( eventKey );

	setActive( list );
};

export default { Base, Section };
