import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';

import Button from '.';

const ButtonGroup = ( { buttons, center } ) => {
	const link = btn => {
		let lnk = '';

		if ( btn.link ) {
			if ( btn.type === 'static' ) {
				switch ( btn.link ) {
					case 'Home':
						lnk = '/';
						break;
					case 'Contact Page':
						lnk = '/contact';
						break;
					case 'Contact Form':
						lnk = '#form';
						break;
					default:
						break;
				}
			} else {
				lnk = `/${ btn.link.split( ' ' )
					.join( '-' ) }`;
			}
		}

		return lnk;
	};

	return (
		<div className="buttonGroup" style={{ margin: center && 'auto' }}>
			{ buttons.map( ( btn, i ) => (
				<Link key={ i } to={ link( btn ) }>
					<Button
						buttonText={ btn.buttonText }
						primary={ btn.variant === 'primary' }
						secondary={ btn.variant === 'secondary' }
						outline={ btn.variant === 'outline' }
					/>
				</Link>
			) )}
		</div>
	);
};

ButtonGroup.propTypes = { buttons: PropTypes.array, center: PropTypes.bool };

export default ButtonGroup;
